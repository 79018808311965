import React, { Component, useEffect, useState } from 'react';
import FraccContent from './FraccContent';
import './Home.scss';
import Slidewithcontrol from './slidewithcontrol'
import Swal from 'sweetalert2';
import { useLocation, withRouter } from 'react-router-dom';
import { Modal } from 'reactstrap';
import { ModalBody } from "reactstrap";
import { ModalHeader } from "reactstrap";
import TerminosCondiciones from './terminos-condiciones';


function Home({match}) {

  // const location = useLocation();
  const [seleccion, setSeleccion] = useState(null);
  var Close = () => setSeleccion(null);
  var Open = () => setSeleccion(true);

  useEffect(() => {
    // Verifica si la ruta actual es '/privacidad'
    if (loc.pathname === '/' ) return;

    else if (loc.pathname === '/privacidad' ) {
      setSeleccion(true);
    } else {
      setSeleccion(false);
    }
  }, []);  


  const [contacto, setContacto] = useState({});
  var loc = useLocation();

  console.log("match", match);
  console.log("location", loc);

  useEffect(e=> {
    if(loc.hash)
    {
      scroll(loc.hash.substring(1));
    }
  },[match]);

  const MostarAdvertencia = async (text) => {
    await Swal.fire({
       icon: "warning",
       title: text
    });
 }

  const validar = (validacion, mensaje) => {
    // console.log("validando: ", validacion);
    if(!validacion)
    {
       // console.log("Validación incorrect1a");
       MostarAdvertencia(mensaje);
       throw mensaje;
    }
    return true;
  }

  var procesando = false;
  const enviarContacto = async () =>{
    validar(contacto.nombre, "Debes escribir tu nombre");
    validar(contacto.apellido, "Debe escribir tu apellido");
    validar(contacto.correo, "Debes escribir tu correo");
    validar(contacto.telefono, "Debes escribir tu telefono");
    validar(contacto.estado, "Debes seleccionar un estado");
    validar(contacto.proyecto, "Debes seleccionar un proyecto");

    if(procesando) return;
    procesando = true;
    console.log("Enviando contacto", contacto);
    var res;
    try {
      res = await fetch("https://amistad.azurewebsites.net/back/contacto", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(contacto)
      });
      res = await res.json();
    } catch (error) {
      res = {ok:false};
    }

    if(res.ok)
    {
      Swal.fire({
        "title" : "En un momento un miembro de nuestro equipo lo contactará",
        "icon" : 'success'
      });
    }
    else {
      Swal.fire({
        "title" : "ups, parece que ha habido un error, te recomendamos que nos contactes a cualquiera de nuestros números",
        "icon" : 'warning'
      });
    }
    procesando = false;
  }

  var items = [
    {src: "/elementos/banner_principal/banner_p1.jpg"},
    {src: "/elementos/banner_principal/1.jpg"},
    {src: "/elementos/banner_principal/2.jpg"},
    {src: "/elementos/banner_principal/3.jpg"},
    {src: "/elementos/banner_principal/4.jpg"},
    {src: "/elementos/banner_principal/5.jpg"}
  ];

  var logoWhite = [
    "/img-white/barcelona.png",
    "/img-white/azaahy.png",
    "/img-white/terramillpa.png",
    "/img-white/mayorales.png",
    "/img-white/anikwa.png",
    "/img-white/senderos.png",
    "/img-white/pedregal.png",
    "/img-white/frey.png" 
  ]

  var imgPhones = [
    {
      class: "imgPhone1 hor",
      src: "/elementos/inicio/tel_padel.png"
    },
    {
      class: "imgPhone2 hor",
      src: "/elementos/inicio/tel_juegos.png"
    },
    ,
    {
      class: "imgPhone3 ver",
      src: "/elementos/inicio/tel_senderos.png"
    },
    ,
    {
      class: "imgPhone4 ver",
      src: "/elementos/inicio/tel_gimnasios.png"
    },
    ,
    {
      class: "imgPhone5 hor",
      src: "/elementos/inicio/tel_relleno.png"
    },
    ,
    {
      class: "imgPhone6 hor",
      src: "/elementos/inicio/tel_relleno.png"
    },
    ,
    {
      class: "imgPhone7 ver",
      src: "/elementos/inicio/tel_canchas.png"
    },
    ,
    {
      class: "imgPhone8 hor",
      src: "/elementos/inicio/tel_asadores.png"
    },
    ,
    {
      class: "imgPhone9 hor",
      src: "/elementos/inicio/tel_hamacas.png"
    },
    ,
    {
      class: "imgPhone10 ver",
      src: "/elementos/inicio/tel_areasverdes.png"
    },
    ,
    {
      class: "imgPhone11 ver",
      src: "/elementos/inicio/tel_relleno2.png"
    },
  ]

  const desarrolloMono = [
    {
      src: "/elementos/desarrollos/monocromaticos/m_pedregal.png",
      nombreFrac: "pedregal",
      src2: "/elementos/desarrollos/color/pedregal.png",
      ubicacion: "CANCÚN",
      precio: "$5,500",
      link: "http://pedregalplusdos.amistadbienesraices.com/"
    },
    {
      src: "/elementos/desarrollos/monocromaticos/m_barcelona.png",
      nombreFrac: "barcelona",
      src2: "/elementos/desarrollos/color/barcelona.png",
      ubicacion: "CANCÚN",
      precio: "$3,500",
      link: "http://barcelona.amistadbienesraices.com/"
    },
    {
      src: "/elementos/desarrollos/monocromaticos/m_azaahy.png",
      nombreFrac: "azaahy",
      src2: "/elementos/desarrollos/color/azaahy.png",
      ubicacion: "CANCÚN",
      precio: "$4,000",
      link: "http://azaahy.amistadbienesraices.com/"
    },
    {
      src: "/elementos/desarrollos/monocromaticos/m_terramillpa.png",
      nombreFrac: "terramillpa",
      src2: "/elementos/desarrollos/color/terramillpa.png",
      ubicacion: "CANCÚN",
      precio: "$1,350",
      link: "http://terramilpa.amistadbienesraices.com/"
    },
    {
      src: "/elementos/desarrollos/monocromaticos/m_mayorales.png",
      nombreFrac: "mayorales",
      src2: "/elementos/desarrollos/color/mayorales.png",
      ubicacion: "CANCÚN",
      precio: "$2,000",
      link: "http://mayorales.amistadbienesraices.com/"
    },
    {
      src: "/elementos/desarrollos/monocromaticos/m_anikwa.png",
      nombreFrac: "anikwa",
      src2: "/elementos/desarrollos/color/anikwa.png",
      ubicacion: "BUENAVENTURA",
      precio: "$1,700",
      link: "http://anikwa.amistadbienesraices.com/"
    },
    {
      src: "/elementos/desarrollos/monocromaticos/m_senderos.png",
      nombreFrac: "senderos",
      src2: "/elementos/desarrollos/color/senderos.png",
      ubicacion: "MONCLOVA",
      precio: "$1,200",
      link: "http://senderos.amistadbienesraices.com/"
    },
    {
      src: "/elementos/desarrollos/monocromaticos/m_frey.png",
      nombreFrac: "frey",
      src2: "/elementos/desarrollos/color/frey.png",
      ubicacion: "CANCÚN",
      precio: "$1,800",
      link: "http://frey.amistadbienesraices.com/",
      classname: "freyImg"
    },
    {
      src: "/elementos/desarrollos/monocromaticos/m_asaurus.png",
      nombreFrac: "asaurus",
      src2: "/elementos/desarrollos/color/asaurus.png",
      ubicacion: "MONCLOVA",
      precio: "$2,000",
      link: "http://asaurus.amistadbienesraices.com/",
      classname: "asaurusImg"
    },
    // {
    //   src: "/elementos/desarrollos/monocromaticos/m_sanan.png",
    //   nombreFrac: "sanan",
    //   src2: "/elementos/desarrollos/color/sanan.png",
    //   ubicacion: "MONCLOVA",
    //   precio: "$2,000",
    //   link: "http://sanantonio.amistadbienesraices.com/",
    //   classname: "freyImg"
    // }
  ]
  var [index, setIndex] = useState(0);
  const [seleccionado, setSeleccionado] = useState("pedregal");

  // const [select, setSelect] = useState("comprar");

  function scroll(id) {
    var el = window.document.getElementById(id);
    window.document.scrollingElement.scroll(0, el.offsetTop);
  }
  

  window.setIndex = setIndex;
  
  return (
    <React.Fragment>
       <img src="/elementos/nosotros/goUp.png" onClick={e=> scroll('inicio')} className="goUp"/>  
      <div className="content-absolute">
        <div className="parts">
          <div className="part-1 titleP">
            <h1>
              ADQUIERE TU <br /> <span>TERRENO</span>{" "}
            </h1>
            <h6>en 3 pasos</h6>
          </div>

          <div className="part-2">
            <div className="circle">
              <img src="/elementos/inicio/paso1.png" alt="paso 1 img" />
              <p className="p1">
                Selecciona <br /> el desarrollo
              </p>
            </div>
            <div className="circle">
              <img src="/elementos/inicio/paso2.png" alt="paso 1 img" />
              <p>
                Escoge <br /> tu terreno
              </p>
            </div>
            <div className="circle">
              <img src="/elementos/inicio/paso3.png" alt="paso 1 img" />
              <p>
                Escoge tu <br /> forma de <br /> pago
              </p>
            </div>
            <h6>& listo!</h6>
          </div>
        </div>
        <div className="part3">
          <h1>
            Tu <span className="bolder">FUTURO</span> inicia aquí <br /> & tu{" "}
            <span className="bolder">PATRIMONIO </span>también <br />
            <span className="smaller">SIN ENGANCHE & ENTREGA INMEDIATA</span>
          </h1>
          <div className="img-container">
            {logoWhite.map(e=>
                <img src={e} alt="" />
              )}
          </div>
          <a href="#">
            <div className="btn-style">
              <a href="#desarrollos"><h1>QUIERO UN TERRENO {">"}</h1></a>
            </div>
          </a>
        </div>

        
      </div>
      <Slidewithcontrol
        items={items}
        index={index}
        setIndex={setIndex}
      ></Slidewithcontrol>
      <div className="secc-invierte background-b">
        <div className="textoBtn">
          <h1>
            Te damos la bienvenida a la <br />{" "}
            <span>mejor inversión de tu vida</span>{" "}
          </h1>
          {/* <a href="#">
            <div className="btn-style2">
              <h1>DESCARGA CATÁLOGO</h1>
            </div>
          </a> */}
        </div>
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/HvSi9rIgiqU"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
      <div className="seccinviertePlus">
        <div className="textoBtn2">
          <h1 className="title">
            Invierte en realidad y <span>GANA PLUSVALÍA</span>{" "}
          </h1>
          <p>"Pensado para brindarte el máximo confort para ti y tu familia"</p>
          <a href="#">
            <div className="btn-style2 shadowO">
              <a href="#desarrollos"><h1>QUIERO CONOCER LOS DESARROLLOS</h1></a>
            </div>
          </a>
        </div>
      </div>
      <div className="img-centro">
      <img className="circleY" src="/elementos/inicio/terrenoCirculo.png" alt="img circulo amarillo amistad" />
        <div className="imagesCen">
          <img className="chico" src="/elementos/inicio/terrenoImg2.png" alt="img chico amistad" />
          <img className="chica" src="/elementos/inicio/terrenoImg.png" alt="img chica amistad" />
        </div>
        <div className="absoluteCentro">
          <div className="infoImg">
            <h1>
              !EN AMISTAD SABEMOS QUÉ ES LO QUE NECESITAS,{" "}
              <span>INVIERTE Y HAZ USO DE TU TERRENO HOY MISMO!</span>{" "}
            </h1>
            <a href="https://wa.me/5219983860033" target="_blank">
              <p> {"> >"} QUIERO APARTAR MI TERRENO</p>
            </a>
          </div>
          <h1 className="p1">
            ENTREGA <br />
            INMEDIATA*
          </h1>
          <h1 className="p2">
            SIN <br /> ENGANCHE*
          </h1>
        </div>
      </div>
      <div className="secc-invertirTe background-b">
        <h1>
          ¿Por qué <span className="coloured-y">NECESITO</span> invertir en un
          Terreno?
        </h1>
        <div className="container-invertir">
          <div className="middle-line"></div>
          <div>
            <img
              src="/elementos/inversion/inversion1.png"
              alt="img inversion 1"
            />
            <div className="infoInvert">
              <h1>SEGURIDAD</h1>
              <p>Te protege ante cualquier vulnerabilidad</p>
            </div>
          </div>
          <div>
            <img
              src="/elementos/inversion/inversion2.png"
              alt="img inversion 2"
            />
            <div className="infoInvert">
              <h1>ACCESIBLE</h1>
              <p>El costo de un terreno es relativamente barato</p>
            </div>
          </div>
          <div>
            <img
              src="/elementos/inversion/inversion3.png"
              alt="img inversion 3"
            />
            <div className="infoInvert">
              <h1>RENTABLE</h1>
              <p>Su valor siempre aumentará, a esto se le llama PLUSVALÍA</p>
            </div>
          </div>
        </div>
        <a href="#">
          <div className="btn-style2 shadowO">
            <a href="https://wa.me/5219983860033" target="_blank"><h1>AGENDA UNA CITA VIRTUAL AHORA</h1></a>
          </div>
        </a>
      </div>
      <div className="secPhone">
        <div className="secPhones">
          <div className="imgPhones">
            {imgPhones.map(e=>
              <img className={e.class} src={e.src} alt="imagen amenidad" />
              )}
          </div>
        </div>
        <div className="info">
          <h1>
            Vive <span>experiencias inolvidables </span>en cada uno de nuestros
            desarrollos
          </h1>
          <p>
            Podrás disfrutar de todas las amenidades del desarrollo desde tu
            primer pago; algunas de las amenidades que disfrutarás son: Zonas de
            descanso, juegos infantiles, gimnasio al aire libre, canchas de úsos
            múltiples, cancha de padel tennis, voleibol playero, ping pong,
            kioskos, asadores & muchas más.
          </p>
          <a href="#">
            <div className="btn-style2 shadowO mauto">
            <a href="#desarrollos"><h1>QUIERO CONOCER LOS DESARROLLOS</h1></a>
            </div>
          </a>
        </div>
      </div>
      <div className="seccParaiso background-b">
        <div className="infoBtn">
          <h1>
            Elige vivir en el <span className="coloured-y">paraíso, </span>elige
            vivir en Cancún
          </h1>
          <p>
            Visualiza tu futuro en el destino turístico más importante de
            México, además de una
            <span> excelente calidad de vida.</span>
          </p>
          {/* <a href="">
            <div className="btn-style2">
            <a href="#desarrollos"><h1>QUIERO UN TERRENO EN EL PARAÍSO</h1></a>
            </div>
          </a> */}
        </div>
        <div className="imgDuo">
          <img
            className="fix"
            src="elementos/inicio/cancun.jpg"
            alt="img cancún"
            width="auto"
            height="100"
          />
          <img
            className="abs"
            src="elementos/inicio/guacamaya.png"
            alt="img cancún"
            width="auto"
            height="100"
          />
        </div>
      </div>
      <div id="desarrollos" className="seccDesarrollos">
        <div className="seccDesarrollos-part1">
          <h1>NUESTROS DESARROLLOS</h1>
          <p>
            Selecciona uno de nuestros desarrollos, cada uno de ellos te hará
            acreedor de un estilo de vida único y exclusivo
          </p>
          <div className="desarrollosImg">
              {
                desarrolloMono.map(i=> 
                  <div
                  className={
                    "content" + (seleccionado == i.nombreFrac ? " seleccionado" : "")
                  }
                  onMouseEnter={(e) => setSeleccionado(i.nombreFrac)}
                > 
                    <img className={i.classname} src={seleccionado == i.nombreFrac ? i.src2 : i.src} alt="img desarrollo"/>
                    <h6>{i.ubicacion}</h6>
                    <a href={i.link} target="_blank"><p>{i.precio}</p></a>
                </div>   
                )}
          </div>
        </div>
      </div>
      <FraccContent nombre={seleccionado}></FraccContent>
      <div id="contacto" className="contacto bg-image">
        <div className="infoContacto">
          <img
            src="/elementos/inicio/logoContacto.png"
            alt="logo amistad contacto"
          />
          <div className="ubicacion-container">
          <div className="ubicacion">
            <h1>Cancún</h1>
          <div className="info-box">
            <div className="direc bor">
              <div>
                <i class="fa fa-map-marker" aria-hidden="true"></i>
                <h1>Dirección</h1>
              </div>
              <p>Av. Las Torres Supermanzana 523 Manzana , 43 Lote 1-Local e, Los Faroles, 77535 Cancún, Q.R.</p>
            </div>
          </div>
          <div className="info-box">
            <div className="telef bor">
              <div>
                <i className="fa fa-phone" aria-hidden="true"></i>
                <h1>Teléfonos</h1>
              </div>
              <p>998 116 6637 <span>(ATC)</span></p>
              <p>998 386 0033 <span>(VENTAS)</span></p>
              <p>998 577 5069 <span>(VENTAS)</span></p>
              {/* <p>998 151 4221 <span>(COBRANZA)</span></p> */}
            </div>
          </div>
          <div className="info-box">
            <div className="correo bor">
              <div>
                <i className="fa fa-envelope-o" aria-hidden="true"></i>
                <h1>Correo Electrónico</h1>
              </div>
                <h6>info.cun@ <br />amistadbienesraices.com</h6>
            </div>
          </div>
          <div className="info-box">
            <div className="horarios bor">
              <div>
                <i className="fa fa-clock-o" aria-hidden="true"></i>
                <h1>Horarios</h1>
              </div>
                <p><span>Lunes a Viernes </span>9:00 a 5:00 p.m.</p>
                <p><span>Sábado </span>9:00 a 2:00 p.m</p>
            </div>
          </div>
          <div className="media">
            <a href="https://www.facebook.com/amistadbr/" target="_blank"><img src="/elementos/fb.png" alt="img facebook amistad" /></a>
            <a href="https://www.instagram.com/amistadbr/" target="_blank"><img src="/elementos/ig.png" alt="img instagram amistad" /></a>
            <a href="https://www.tiktok.com/@amistadbr" target="_blank"><img src="/elementos/tt.png" alt="img tiktok amistad" /></a>
            <a href="https://www.youtube.com/channel/UCHMljReTydWXmp-ZyTg6j-Q" target="_blank"><img src="/elementos/yt.png" alt="img youtube amistad" /></a>
              <p>amistadbr</p>
          </div>
          </div>
          <div className="ubicacion end">
          <h1>Monclova</h1>
          <div className="info-box">
            <div className="direc">
              <div>
                <i className="fa fa-map-marker" aria-hidden="true"></i>
                <h1>Dirección</h1>
              </div>
              <p>Blvd Harold R. Pape 738, Zona Centro, 25700 Monclova, Coah</p>
            </div>
          </div>
          <div className="info-box">
            <div className="telef">
              <div>
                <i className="fa fa-phone" aria-hidden="true"></i>
                <h1>Teléfonos</h1>
              </div>
              <p>866 133 6927 <span>(ATC)</span></p>
              <p>866 209 5743 <span>(VENTAS)</span></p>
              <p>866 213 7258 <span>(COBRANZA)</span></p>
            </div>
          </div>
          <div className="info-box">
            <div className="correo">
              <div>
                <i className="fa fa-envelope-o" aria-hidden="true"></i>
                <h1>Correo Electrónico</h1>
              </div>
                <h6>info.mva@ <br />amistadbienesraices.com</h6>
            </div>
          </div>
          <div className="info-box">
            <div className="horarios">
              <div>
                <i className="fa fa-clock-o" aria-hidden="true"></i>
                <h1>Horarios</h1>
              </div>
                <p><span>Lunes a Sábado </span>9:00 a 7:00 p.m.</p>
            </div>
          </div>
          <div className="media">
            <a href="https://www.facebook.com/amistad.mon" target="_blank"><img src="/elementos/fb.png" alt="img facebook amistad" /></a>
              <p>amistad.mon</p>
          </div>
          </div>
          </div>
        </div>
        <div className="contactar">
          <h1>CONTACTO</h1>
          <div className="duo">
            <div className="inp">
              <p>Nombre(s): </p>
              <input type="text" placeholder='Escribe tu nombre' onChange={e=>setContacto({...contacto, nombre: e.target.value})} />
            </div>
          <div className="inp">
            <p>Apellidos: </p>
            <input type="text" placeholder='Escribe tus apellidos'  onChange={e=>setContacto({...contacto, apellido: e.target.value})}/>
          </div>
          </div>
          
          <div className="inp">
            <p>E-mail: </p>
            <input type="email" placeholder='Compartenos tu Correo electrónico'  onChange={e=>setContacto({...contacto, correo: e.target.value})}/>
          </div>
          <div className="inp">
            <p>Número Telefónico: </p>
            <input type="text" placeholder='Escribe tu número de teléfono'  onChange={e=>setContacto({...contacto, telefono: e.target.value})}/>
          </div>
          <div className="duo">
          <div className="inp">
            <p>Estado* </p>
            <select name="" id=""  onChange={e=>setContacto({...contacto, estado: e.target.value})}>
              <option value="0">Selecciona el estado</option>
              <option value="aguascalientes">Aguascalientes</option>
              <option value="baja_california">Baja California</option>
              <option value="baja_california_sur">Baja California Sur</option>
              <option value="campeche">Campeche</option>
              <option value="chiapas">Chiapas</option>
              <option value="chiahuahua">Chiahuahua</option>
              <option value="CDMX">CDMX</option>
              <option value="coahuila">Coahuila</option>
              <option value="colima">Colima</option>
              <option value="durango">Durango</option>
              <option value="guanajuato">Guanajuato</option>
              <option value="guerrero">Guerrero</option>
              <option value="hidalgo">Hidalgo</option>
              <option value="jalisco">Jalisco</option>
              <option value="michoacan">Michoacán</option>
              <option value="morelos">Morelos</option>
              <option value="nayarit">Nayarit</option>
              <option value="nuevo_leon">Nuevo León</option>
              <option value="oaxaca">Oaxaca</option>
              <option value="puebla">Puebla</option>
              <option value="queretaro">Querétaro</option>
              <option value="quintana_roo">Quintana Roo</option>
              <option value="san_luis_potosi">San Luis Potosí</option>
              <option value="sinaloa">Sinaloa</option>
              <option value="sonora">Sonora</option>
              <option value="tabasco">Tabasco</option>
              <option value="tamaulipas">Tamaulipas</option>
              <option value="tlaxcala">Tlaxcala</option>
              <option value="veracruz">Veracruz</option>
              <option value="yucatan">Yucatán</option>
              <option value="zacatecas">Zacatecas</option>
            </select>
          </div>
          <div className="inp">
            <p>Proyecto de interés: </p>
            <select onChange={e=>setContacto({...contacto, proyecto: e.target.value})}>
              <option value="0">Selecciona el Proyecto</option>
              <option value="azaahy">Azaahy</option>
              <option value="pedregal">Pedregal</option>
              <option value="barcelona">Barcelona</option>
              <option value="terramillpa">Terramillpa</option>
              <option value="frey">Frey</option>
              <option value="mayorales">Mayorales</option>
              <option value="anikwa">Anikwa</option>
              <option value="senderos">Senderos</option>
              <option value="otros">Otros</option>
            </select>
          </div>
          </div>
          
          <div className="inp">
            <p>Mensaje: </p>
              <textarea name="" id="" cols="30" rows="5" placeholder='Escribe el Mensaje'  onChange={e=>setContacto({...contacto, mensaje: e.target.value})}></textarea>
          </div>
          <div className="btnContactar">
            {/* <a href=""><div className="btn-style2"><h1>DESCARGA CATÁLOGO</h1></div></a> */}
            <a onClick={enviarContacto}><div className="btn-style2"><h1>ENVIAR MENSAJE</h1></div></a>
          </div>
        </div>
        <div className="avisoPriv">
          <p>© {new Date().getFullYear()} Todos los derechos reservados. | </p>
          <a href="/privacidad" onClick={Open}> Aviso de privacidad</a>
        </div>
      </div>
      <div className="avisoPrivMovil">
          <p>© {new Date().getFullYear()} Todos los derechos reservados. | </p>
          <a href="/privacidad" onClick={Open}> Aviso de privacidad</a>
        </div>
      <Modal
          isOpen={seleccion != null}
          // toggle={() => setOpen(!open)}
          backdrop="static"
          centered
          size="lg"
        >
          <ModalHeader toggle={Close}>Aviso de Privacidad</ModalHeader>
          <ModalBody>
            {!seleccion ? (
              ""
            ) : (
              <div className="avisoYPriv">
                <TerminosCondiciones></TerminosCondiciones>
              </div>
            )}
          </ModalBody>
        </Modal>
    </React.Fragment>
  );
}

export default withRouter(Home);